import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/font/font.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAnimateNumber from 'vue-animate-number'
import { Search, Popup } from 'vant'
import 'vant/lib/index.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
Vue.use(VueAnimateNumber)
Vue.use(ElementUI)
Vue.use(Search)
Vue.use(Popup)

Vue.config.productionTip = false
Vue.config.productionSourceMap = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
