<template>
  <router-view v-if="isRouterAlive"></router-view>
</template>

<script>
export default {
  provide(){
    return {
      reload:this.reload
    }
  },
  data () {
    return {
      isRouterAlive:true
    }
  },
  methods: {
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
 }
}
</script>

<style lang="less">
@import './views/fn.less';
html,body{
  height: 100%;
  font-family: PuHuiTi;
}
 *{
   margin: 0;
   padding: 0;
 }
 li{
   list-style: none;
 }
 a{
   text-decoration: none;
   color: #101218;
 }
  .video{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      .video-js{
        width: 100%!important;
        height: 100%!important;
    }
  }
  .el-carousel .el-carousel__indicators{
    display: flex;
    flex-direction: row;
  }
</style>
