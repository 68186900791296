
import Vue from 'vue'
import VueRouter from 'vue-router'
// 首页
// import Home from '../views/Home.vue'
// 新闻
// import News from '../views/news.vue'
// import NewsDetails from '../views/newsDetails.vue'
// 招贤纳士
// import JoinUs from '../views/joinUs.vue'
// import Join from '../views/joinDetails.vue'
// 解决方案------------消毒供应中心
// import Solution1 from '../views/Solution1'
// import TraceBack from '../views/solution/TraceBack.vue'
// import Cases from '../views/solution/Cases.vue'
// 关于老肯
// import AboutUs from '../views/about-us/AboutUs.vue'
// 服务支持
// import Support from '../views/support/Support.vue'
// 地图详情
// import MapInfo from '../views/home/placeList.vue'
// 车间详情
// import Plant from '../views/solution/plantDetail.vue'
// 案例详情
// import CaseDetail from '../views/solution/casesDetail.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: resolve => (require(['../views/Home.vue'], resolve))
  }, {
    path: '/news',
    name: 'news',
    component: resolve => (require(['../views/news.vue'], resolve))
  }, {
    path: '/newsDetails',
    name: 'newsDetails',
    component: resolve => (require(['../views/newsDetails.vue'], resolve))
  }, {
    path: '/mapInfo',
    name: 'mapInfo',
    component: resolve => (require(['../views/home/placeList.vue'], resolve))
  }, {
    path: '/plant',
    name: 'plant',
    component: resolve => (require(['../views/solution/plantDetail.vue'], resolve))
  }, {
    path: '/joinus',
    name: 'joinus',
    component: resolve => (require(['../views/joinUs.vue'], resolve))
  }, {
    path: '/join',
    name: 'join',
    component: resolve => (require(['../views/joinDetails.vue'], resolve))
  }, {
    path: '/solution',
    name: 'solution',
    component: resolve => (require(['../views/Solution1'], resolve))
  }, {
    path: '/traceBack',
    name: 'traceBack',
    component: resolve => (require(['../views/solution/TraceBack.vue'], resolve))
  }, {
    path: '/cases',
    name: 'cases',
    component: resolve => (require(['../views/solution/Cases.vue'], resolve))
  }, {
    path: '/caseDetail',
    name: 'caseDetail',
    component: resolve => (require(['../views/solution/casesDetail.vue'], resolve))
  }, {
    path: '/aboutUs',
    name: 'aboutUs',
    component: resolve => (require(['../views/about-us/AboutUs.vue'], resolve))
  }, {
    path: '/support',
    name: 'support',
    component: resolve => (require(['../views/support/Support.vue'], resolve))
  }
]
const router = new VueRouter({
  // mode:'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    next()
})
export default router
